import styled from "styled-components"
import { getBreakpoint } from "../../../utils/theme"

export const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  min-height: var(--footer-height);
  background-color: var(--color-primary);
  padding: 0.3125rem;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 1rem;
  color: white;
  line-height: 140%;
  span {
    font-weight: 700;
    font-size: 1.25rem;
  }
  text-align: center;
  width: 100%;
`

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  gap: 2rem;

  a {
    color: white;
  }
`
