import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as Styled from "./styled"

const Logo = (props) => {
  return (
    <Styled.LogoWrapper to="/" className={`${props.className}`}>
      <StaticImage
        src="../../../images/logo-horizontal.png"
        alt="Bnbdays Logo"
        height={42}
        loading="eager"
        layout="constrained"
        placeholder="tracedSVG"
      />
    </Styled.LogoWrapper>
  )
}

export default Logo
