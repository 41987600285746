import React, { useState, useEffect } from "react"
import "./styled.tsx"
import gTagInit from "./gtag-injector"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as Styled from "./styled"

// TODO: Use DatoCMS to internationalization

const LOCAL_STORAGE_COOKIE_KEY = "cookieStatus"

export default function CookiesManagerProvider({ data }) {
  const [showCookiesSettings, setShowCookiesSettings] = useState(false)
  const [analyticsCookiesEnabled, setAnalyticsCookiesEnabled] = useState(false)
  const [areCookiesAccepted, setAreCookiesAccepted] = useState("")
  const [areCookiesEditedThisSession, setAreCookiesEditedThisSession] =
    useState(false)
  const [areCookiesShown, setAreCookiesShown] = useState(false)

  const cookieAccepted = function () {
    setAreCookiesAccepted("accepted")
    setAreCookiesEditedThisSession(true)
    window.localStorage.setItem(LOCAL_STORAGE_COOKIE_KEY, "accepted")
    gTagInit()
  }

  const saveCookiesSettings = function () {
    if (analyticsCookiesEnabled) {
      cookieAccepted()
    } else {
      cookieDeclined()
    }
  }
  const showCookies = function () {
    setAreCookiesShown(true)
  }
  const cookieDeclined = function () {
    setAreCookiesAccepted("declined")
    setAreCookiesEditedThisSession(true)
    window.localStorage.setItem(LOCAL_STORAGE_COOKIE_KEY, "declined")
  }

  useEffect(() => {
    setTimeout(() => {
      showCookies()
    }, 3000)
    setAreCookiesAccepted(
      window.localStorage.getItem(LOCAL_STORAGE_COOKIE_KEY) ?? ""
    )
    if (window.localStorage.getItem(LOCAL_STORAGE_COOKIE_KEY) === "accepted") {
      gTagInit()
    }
  }, [])

  const CookiesInfoContainer = (
    <Styled.CookiesWrapperInfoContainer>
      <StaticImage
        src="../../images/cookies.png"
        alt="Dibujo de una galleta mordida"
        height={200}
        loading="eager"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <h3>Deliciosas Cookies</h3>
      <p>
        Para una mejor experiencia es imprescindible que aceptes nuestra
        política de cookies.
      </p>
    </Styled.CookiesWrapperInfoContainer>
  )

  const CookiesConfigurationContainer = (
    <Styled.CookiesWrapperInfoContainer>
      <p>
        Puedes activar o desactivar las siguientes cookies marcando la casilla
        correspondiente, estando desactivadas por defecto. Si quieres más
        información, consulta nuestra&nbsp;
        <Link to="/cookies_policy">POLÍTICA DE COOKIES</Link>.
      </p>
      <Styled.CookiesSettingsContainer>
        <Styled.CookiesSetting>
          <p>Cookies de funcionalidad</p>
          <Styled.Switch>
            <input className="checkbox" type="checkbox" checked disabled />
            <span className="slider round"></span>
          </Styled.Switch>
        </Styled.CookiesSetting>
        <Styled.CookiesSetting>
          <p>Cookies de Análisis</p>
          <Styled.Switch>
            <input
              className="checkbox"
              type="checkbox"
              onClick={(event: any) =>
                setAnalyticsCookiesEnabled(!analyticsCookiesEnabled)
              }
            />
            <span className="slider round"></span>
          </Styled.Switch>
        </Styled.CookiesSetting>
      </Styled.CookiesSettingsContainer>
      <p>
        Al pulsar "Guardar", se guardará la selección de cookies que hayas
        realizado.
      </p>
    </Styled.CookiesWrapperInfoContainer>
  )

  return (
    <>
      {(areCookiesAccepted === "" || areCookiesAccepted === "declined") && (
        <Styled.CookiesHover
          className="fade-in"
          style={{
            display:
              areCookiesShown &&
              (areCookiesAccepted === null ||
                areCookiesEditedThisSession === false)
                ? "flex"
                : "none",
          }}
        >
          <Styled.CookiesWrapper>
            {showCookiesSettings
              ? CookiesConfigurationContainer
              : CookiesInfoContainer}

            {showCookiesSettings ? (
              <Styled.CookiesActions>
                <button className="sole-button" onClick={saveCookiesSettings}>
                  Guardar
                </button>
              </Styled.CookiesActions>
            ) : (
              <Styled.CookiesActions>
                <button
                  onClick={() => {
                    setShowCookiesSettings(true)
                  }}
                >
                  Configurar Cookies
                </button>
                <button onClick={cookieAccepted}>Aceptar Cookies</button>
              </Styled.CookiesActions>
            )}
          </Styled.CookiesWrapper>
        </Styled.CookiesHover>
      )}
    </>
  )
}
