function extractNestedValue(pathArray: PropertyKey[], themeObject: Object) {
  let obj = themeObject

  pathArray.forEach((value) => {
    obj = obj[value]
  })

  if (!obj) {
    throw new Error(
      `Too many nesting levels for theme property - Expected 3, got ${pathArray.length}: [${pathArray}]`
    )
  }

  if (obj.hasOwnProperty("value")) return obj["value"]

  console.group()
  console.warn(`No value property in Object:`)
  console.warn(JSON.stringify(obj, null, 2))
  console.groupEnd()

  throw new Error(
    `No value property in Object: ${JSON.stringify(obj, null, 2)}`
  )
}

// getColor('text:black')
// getColor('palette:purple:500')
export function getColor(valueString) {
  return ({ theme }) => {
    const tokenPath = valueString.split(":")
    const isNestedValue = tokenPath.length > 1

    if (!isNestedValue) return theme.color[tokenPath].value

    return extractNestedValue(tokenPath, theme.color)
  }
}

export function getBreakpoint(value: PropertyKey, minmax?: String) {
  return ({ theme }) => {
    const breakpoint = theme.breakpoints[value].value

    if (minmax && minmax.toUpperCase() === "MAX") {
      return `@media (max-width: ${breakpoint}px)`
    }

    return `@media (min-width: ${breakpoint}px)`
  }
}
