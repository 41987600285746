import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getBreakpoint } from "../../../utils/theme"

export const TopNavigationBarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  z-index: 99;

  display: grid;
  grid-template-columns: minmax(1rem, auto) minmax(1rem, 67.5rem) minmax(
      1rem,
      auto
    );
  grid-template-rows: 1.875rem auto;
`

export const TopNavigationBarSocialBackground = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  min-height: var(--nav-social-height);
  background-color: var(--color-primary-dark);
`

export const TopNavigationBarSocial = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }
  span {
    color: white;
    font-size: 1rem;
    font-weight: 700;
  }
`

export const TopNavigationBarSocialItems = styled.div`
  display: flex;
  gap: 0.5rem;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

export const TopNavigationBarContent = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;

  box-sizing: border-box;
  padding: 0.3125rem 0;
  width: 100%;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;

  ${getBreakpoint("laptop", "MAX")} {
    grid-template-columns: auto auto;
  }

  .blog-logo {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
  }

  p,
  a,
  span {
    color: var(--color-primary-dark);
    font-size: 1.25rem;
    font-weight: 500px;
  }

  z-index: 100;
`

export const TopNavigationBarContentBackground = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  min-height: var(--nav-height);
  background-color: #ffffffc0;
  backdrop-filter: blur(6px);
`

export const TopNavigationBarLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  grid-column-start: 2;
  grid-column-end: 2;

  &.no-actions {
    grid-column-end: 4;
    justify-content: flex-end;
  }

  p {
    margin: 0;
  }
  ${getBreakpoint("laptop", "MAX")} {
    display: none;
  }
`

export const TopNavigationBarActions = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  ${getBreakpoint("laptop", "MAX")} {
    grid-column-start: 2;
    grid-column-end: 2;

    .link {
      display: none;
    }
  }

  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  p {
    margin: 0px;
  }
`
