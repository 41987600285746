import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Logo from "../../atoms/logo"
import * as Styled from "./styled"

// TODO: Extract this texts from statics texts DatoCMS (tranlated)
const Footer = ({ links }) => {
  return (
    <Styled.FooterWrapper>
      <Styled.FooterContent>
        <span>BNBDAYS</span>
        <Styled.LinksWrapper>
          {links?.map(({ name, uri }, index) => {
            return (
              <Link key={`footer__link_${index}`} to={uri}>
                {name}
              </Link>
            )
          })}
        </Styled.LinksWrapper>
      </Styled.FooterContent>
    </Styled.FooterWrapper>
  )
}

export default Footer
