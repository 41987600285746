import React from "react"
import { ThemeProvider } from "styled-components"
import CookiesManagerProvider from "../../providers/cookies-manager-provider/cookies-manager-provider"
// import { graphql } from "gatsby";

import TopNavigationBar from "../../components/molecules/top-navigation-bar"

import designToken from "../../providers/design-tokens.tokens.json"

import "../../styles/global.css"
import Footer from "../../components/molecules/footer"
import { graphql } from "gatsby"
import { createContext } from "react"
import { AppStateProvider } from "../../providers/context-provider/app-context"

interface LayoutProps {
  children: React.ReactNode | React.ReactNode[]
  data?: any
  location?: any
  pageContext?: any
}

const Layout = ({ children, data, location, pageContext }: LayoutProps) => {
  const freeCookiesSpace = [
    "/cookies_and_privacy_policies",
    "/privacy_policy",
    "/cookies_policy",
  ]
  return (
    <AppStateProvider value={{}}>
      <ThemeProvider theme={designToken}>
        <TopNavigationBar
          locale={pageContext?.locale}
          data={data?.navBarData?.nodes?.[0]}
        />

        {children}
        {!freeCookiesSpace.includes(location.pathname) && (
          <CookiesManagerProvider data={data}></CookiesManagerProvider>
        )}
        <Footer {...data?.footer?.nodes?.[0]}></Footer>
      </ThemeProvider>
    </AppStateProvider>
  )
}

export default Layout
