export default function gTagInit() {
  const script = window.document.createElement("script")
  script.async = true
  script.defer = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-F6GMWM5QKS`
  script.addEventListener("load", function () {
    if (window["dataLayer"]) {
      console.log("Launching event from gTag");
      window["dataLayer"].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      })
    }
  })
  window?.document?.querySelector("head")?.appendChild(script)
}
