import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useAppState } from "../../../providers/context-provider/app-context"
import Logo from "../../atoms/logo"

import * as Styled from "./styled"

const TopNavigationBar = ({ locale, data }) => {
  const {
    zonaPlayeraText,
    zonaBiambideraText,
    pressText,
    contactText,
    showZonaBiambidera,
    showZonaPlayera,
    showContact,
    showPress,
    languageDisplayName,
    pressLink,
    contactLink,
    showSearch,
    linkedinUrl,
    facebookUrl,
    instagramUrl,
  } = data || {}

  const appState: any = useAppState()

  return (
    <Styled.TopNavigationBarWrapper>
      <Styled.TopNavigationBarSocialBackground></Styled.TopNavigationBarSocialBackground>
      <Styled.TopNavigationBarSocial>
        <p>
          {languageDisplayName} | <span>{locale?.toUpperCase()}</span>
        </p>
        <Styled.TopNavigationBarSocialItems>
          <a href={linkedinUrl} target="_blank">
            <StaticImage
              src="../../../images/linkedin.png"
              alt="Icono Linkedin"
              height={24}
              loading="eager"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </a>
          <a href={facebookUrl} target="_blank">
            <StaticImage
              src="../../../images/facebook.png"
              alt="Icono Facebook"
              height={24}
              loading="eager"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </a>
          <a href={instagramUrl} target="_blank">
            <StaticImage
              src="../../../images/instagram.png"
              alt="Icono Instagram"
              height={24}
              loading="eager"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </a>
        </Styled.TopNavigationBarSocialItems>
      </Styled.TopNavigationBarSocial>
      <Styled.TopNavigationBarContentBackground></Styled.TopNavigationBarContentBackground>
      <Styled.TopNavigationBarContent>
        <Logo className="blog-logo" />
        <Styled.TopNavigationBarLinksWrapper
          className={`${
            !showPress && !showContact && !showSearch ? "no-actions" : ""
          }`}
        >
          {showZonaPlayera && (
            <Link to="/zona-playera" className="link">
              <img
                src={`../images/${
                  "/zona-playera" === appState?.activeZoneUserTab
                    ? "elipse_playera_filled.png"
                    : "elipse_playera.png"
                }`}
                alt="Circulo amarillo"
                height={32}
                loading="eager"
                placeholder="tracedSVG"
              />
              <p>{zonaPlayeraText}</p>
            </Link>
          )}

          {showZonaBiambidera && (
            <Link to="/zona-biambidera" className="link">
              <img
                src={`../images/${
                  "/zona-biambidera" === appState?.activeZoneUserTab
                    ? "elipse_biambidera_filled.png"
                    : "elipse_biambidera.png"
                }`}
                alt="Circulo verde"
                height={32}
                loading="eager"
                placeholder="tracedSVG"
              />
              <p>{zonaBiambideraText}</p>
            </Link>
          )}
        </Styled.TopNavigationBarLinksWrapper>
        {(showPress || showContact || showSearch) && (
          <Styled.TopNavigationBarActions>
            {showPress && (
              <Link to="/" className="link">
                <p>{pressText}</p>
              </Link>
            )}

            {showContact && (
              <Link to="/" className="link">
                <p>{contactText}</p>
              </Link>
            )}
            {showSearch && (
              <Link to="/" className="search-mag">
                <StaticImage
                  src="../../../images/magnifying.png"
                  alt="Lupa de búsqueda"
                  height={42}
                  loading="eager"
                  layout="constrained"
                  placeholder="tracedSVG"
                />
              </Link>
            )}
          </Styled.TopNavigationBarActions>
        )}
      </Styled.TopNavigationBarContent>
    </Styled.TopNavigationBarWrapper>
  )
}

export default TopNavigationBar
