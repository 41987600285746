import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getBreakpoint } from "../../utils/theme"

export const CookiesHover = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  background-color: #11111166;
  animation-name: fade-in;
  animation-duration: 0.5s;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const CookiesWrapper = styled.div`
  box-shadow: 0px 0.25 0.25 rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 0.625rem;

  width: 100%;
  max-width: calc(var(--content-max-width) / 2);
  min-width: calc(var(--content-max-width) / 4);
`

export const CookiesWrapperInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 0.5rem 2rem;
  p {
    text-align: center;
    color: var(--color-gray-dark);
  }

  .gatsby-image-wrapper {
    max-width: 8rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--color-primary-dark);
    margin-bottom: 0;
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
  }
`

export const CookiesActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  button {
    width: 100%;
    min-height: 2.5rem;
    margin: 0;
    padding: 0;

    background-color: var(--color-primary);
    border: none;

    border-radius: 0 0 0.625rem 0;
    color: white;
    font-weight: 500;

    cursor: pointer;
    font-size: 1rem;
  }

  button:first-child {
    border-radius: 0 0 0 0.625rem;
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

  button.sole-button {
    background-color: var(--color-primary);
    color: white;
    border-radius: 0 0 0.625rem 0.625rem;
  }
`

export const CookiesSettingsContainer = styled.div`
  position: relative;
  width: 100%;
`

export const CookiesSetting = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0.5rem 0;
    color: var(--color-primary-dark);
    font-weight: 600;
  }
`

export const Switch = styled.label`
  /* The switch - the box around the slider */
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--color-primary);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
